import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from 'src/app/authentication/services/auth/auth.service';
import { DataService } from 'src/app/shared/services/data/data.service';
import { SnackBarService } from 'src/app/shared/services/snackBar/snack-bar.service';
import { AuthData } from 'src/app/shared/shared-components/models/common-models';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public _authService: AuthService,
    public _snackBar: SnackBarService,
    private router: Router,
    public _dataService: DataService
  ) { }
  // add comments
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = request;

    const currentUser = this._dataService.getUserAuthData()
    // console.log(currentUser);
    if (currentUser) {
      authReq = this.addTokenHeader(request, currentUser.access);
    }
    return next.handle(authReq).pipe(
      catchError((err) => {
        let error = '';
        let error_message = err.error?.message?.detail || '';
        if (!window.navigator.onLine) {
          error_message = '';
          this.noInternetSnackBar();
        }
        if ([401].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized response returned from api
          if (err.url.includes('auth/token/refresh/')) {
            this.logoutUser(err);
          } else {
            if (localStorage.getItem('ifUserLoggedin')) {
              return this.handle401Error(authReq, next);
            }
          }
        }
        if ([403].indexOf(err.status) !== -1) {
          if (localStorage.getItem('ifUserLoggedin')) {
            error_message = '';
            this.noAccessSnackBar();
            setTimeout(() => {
              this.router.navigate(['/home']).then(() => {
                location.reload();
              });
            }, 3000);
          }
        }
        // console.log(err);
        if (err.error != undefined) {
          error = err.error.message;
        } else if (err.statusText != undefined) {
          error = err.statusText;
        }
        if (error_message != '') {
          this._snackBar.customSnackBar(error_message, 'error');
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // console.log(this.isRefreshing,'..............')
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const user = this._dataService.getUserAuthData()
      const data = {
        refresh: user.refresh,
        node: localStorage.getItem('nodeID'),
      };
      return this._dataService.refreshToken(data).pipe(
        switchMap((res) => {
          this.isRefreshing = false;
          this._dataService.setUserAuthData({ ...user, ...res.data })
          if (!res.data.policy_accepted) {
            this._dataService.newPolicyUpdateReceived.next(
              res.data.current_policy
            );
          }
          this.refreshTokenSubject.next(res.data.access);
          return next.handle(this.addTokenHeader(request, res.data.access));
        }),
        catchError((err) => {
          this.logoutUser(err);
          return throwError(err);
        })
      );
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    // console.log(user);
    const headers = {
      authorization: `Bearer ${token}`,
      // 'user-id': user.user_id.toString(),
      // 'tenant-id': user.tenant_id.toString(),
      'Accept-Language': localStorage.getItem('lang') || 'en',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    return request.clone({
      setHeaders: headers,
    });
  }

  logoutUser(err) {
    this.isRefreshing = false;
    this.tenantConfigUpdated();
    if ([401].indexOf(err.status) !== -1) {
      this._authService.logout();
    }
  }

  noInternetSnackBar() {
    this._snackBar.customSnackBar(
      'Network Error! Check your Internet Connection',
      'error'
    );
  }

  noAccessSnackBar() {
    this._snackBar.customSnackBar(
      'User role was updated, Redirecting to home page.',
      'error'
    );
  }

  tenantConfigUpdated() {
    this._snackBar.customSnackBar(
      'User role was updated, logging you out.',
      'error'
    );
  }
}
