import { NgClass } from '@angular/common';
import { Component, inject,} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { SnackBarData } from './snack-bar-data.model';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  standalone: true,
  imports: [NgClass, MatIconModule, TranslateModule],
})
export class SnackBarComponent {

  data:SnackBarData=inject(MAT_SNACK_BAR_DATA)


}
