import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/core/component/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(public MatSnackBar: MatSnackBar) { }

  // Method to toast success/error messages (custom)
  customSnackBar(message: string, type: TSnackBarType, icon :string = type, duration = 3000) {

    this.MatSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        message,
        type: type,
        icon: this.getToastIcon(icon)
      },
      panelClass: [type + '-toast'],
      duration: duration
    });
  }

  getToastIcon(icon: string): string {
    switch (icon) {
      case 'success':
        return 'check';
      case 'error':
        return 'warning';
      case 'warn':
        return 'warning_outline';
      case 'info':
        return 'info';
      default:
        return icon
    }

  }
}

type TSnackBarType='success' | 'error' | 'warn' | 'info'