<div class="snack-container {{ data.type }}-toast">
  <div class="snack-container-message">
    <div class="d-flex align-items-center">
      <mat-icon [ngClass]="{'info-color':data.type==='info'}"  class="icon-size">
        {{data.icon}}
      </mat-icon>
      <span class="ml-3" [ngClass]="{'info-color':data.type==='info'}">{{ data.message | translate }}</span>
    </div>
  </div>
</div>
