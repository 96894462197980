import { Routes } from '@angular/router';
import { authenticationRoutes } from './authentication/authentication-routing';
import { canMatchFeature } from './core/guard/canMatchFeature';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/features-routing').then((m) => m.featuresRoutes),
    canMatch: [canMatchFeature],
  },
  {
    path: 'public/questionnaire',
    loadComponent: () =>
      import(
        './public/questionnaire-details-customer-view/questionnaire-details-customer-view.component'
      ).then((m) => m.QuestionnaireDetailsCustomerViewComponent),
  },
  ...authenticationRoutes,
  { path: '**', redirectTo: '' },
];
