import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { tap } from 'rxjs';
import { DataService } from 'src/app/shared/services/data/data.service';
import { SnackBarService } from 'src/app/shared/services/snackBar/snack-bar.service';

@Component({
  selector: 'app-accept-privacy-policy',
  templateUrl: './accept-privacy-policy.component.html',
  styleUrls: ['./accept-privacy-policy.component.scss'],
  standalone: true,
  imports: [
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    RouterLink,
    TranslateModule,
  ],
})
export class AcceptPrivacyPolicyComponent {
  termsAccepted = false;
  submitted = false;

  constructor(
    public dialogRef: MatDialogRef<AcceptPrivacyPolicyComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _dataService: DataService,
    private _snackBar: SnackBarService
  ) {
    dialogRef.disableClose = true;
  }

  close() {
    this.dialogRef.close();
  }

  acceptTerms() {
    this.submitted = true;
    const params = { accepted_policy: this.data };

    this._dataService
      .editUserDetails(params)
      .pipe(
        tap(() => {
          this._snackBar.customSnackBar(
            'Privacy policy has been accepted',
            'success'
          );
          this.dialogRef.close();
        })
      )
      .subscribe();
  }
}
