import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AcceptPrivacyPolicyComponent } from '../dialogs/accept-privacy-policy/accept-privacy-policy.component';
import { DataService } from 'src/app/shared/services/data/data.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwitchService {
  _supplyChainList$ = new BehaviorSubject<any[]>([]);
  selected = ''
  constructor(private dialog: MatDialog, private _dataService: DataService, private router: Router) { }

  viewUpdatePolicyPopUp(policy_id?) {
    this.dialog.open(AcceptPrivacyPolicyComponent, {
      width: '40vw',
      height: 'auto',
      maxHeight: '80vh',
      data: policy_id
    });
  }

  toggleDisplayDropdown(url) {
    const currentRoute = url;
    const routesToHideDropdown = [
      'connection-details',
      'company-profile',
      'transaction-detail',
      'stock-detail',
      'order-details',
      'questionnaire',
      'verification-details',
      'details',
      'user-profile',
      'receive-stock',
      'create-stock',
      'add-connections',
      'create-order',
      'tracker'
    ];
    const hideDropDown = routesToHideDropdown.some((route) =>
      currentRoute.includes(route)
    );
    return hideDropDown

  }


  setSupplyChains(): void {
    const params = { keyword: '' };
    const userData = this._dataService.userDetails;

    this._dataService.getSupplyChainList(params).subscribe((response) => {
      const supplyChainList = response.data.results;

      if (supplyChainList.length > 0) {
        const supplyChainId = localStorage.getItem('supplyChainId');

        this.selected = this.getSelectedSupplyChainId(supplyChainList, supplyChainId, userData);

        this._supplyChainList$.next(supplyChainList);

        const previousSupplyChainID = localStorage.getItem('supplyChainId');

        if (!previousSupplyChainID || previousSupplyChainID !== this.selected) {
          this.setSupplyChainConfig();
        }
      }
    });
  }

  getSelectedSupplyChainId(supplyChainList: any[], supplyChainId: string | null, userData: any): string {
    if (supplyChainId && this.isItemAvailable(supplyChainList, 'id', supplyChainId)) {
      return supplyChainId;
    } else {
      const defaultSupplyChainId = userData.default_supply_chain;
      return this.isItemAvailable(supplyChainList, 'id', defaultSupplyChainId) ?
        defaultSupplyChainId :
        supplyChainList[0]?.id || ''; // Using optional chaining to avoid potential null/undefined error
    }
  }


  setSupplyChainConfig() {
    const value = this.selected
    localStorage.setItem('supplyChainId', value);
    this.updateDefaultSupplyChain(value);
  }

  //Update default supplyChain
  private updateDefaultSupplyChain(id) {
    const params = {
      default_supply_chain: id,
    };
    this._dataService.editUserDetails(params).subscribe((res) => {
      const userData = res.data
      this._dataService.setUserData(userData)
      this._dataService.supplyChainIdSignal.set(userData.default_supply_chain)
    }
    )
  }

  private isItemAvailable(list: any[], key, id) {
    return list.some(item => item[key] === id)
  }


  setActiveNode(nodeId: string) {
    const user = this._dataService.getUserAuthData()
    const data = {
      refresh: user.refresh,
      node: nodeId,
    };
    return this._dataService.refreshToken(data).subscribe((res) => {
      this._dataService.setUserAuthData({ ...user, ...res.data })
      this._dataService.nodeId.set(nodeId)
      this.setSupplyChains();
      this.handleNavigation()
    });
  }

  private handleNavigation() {
    this.router.navigateByUrl('/home')
  }

  getActiveCompany():void {
    this._dataService.getMemberDetails().subscribe((res) => {
      const userData = res.data;
      this._dataService.setUserData(userData)
      if (userData.managing_nodes.length > 0) {
        this._dataService.userDetails = userData
        const nodeId = userData.default_node || userData.managing_nodes[0].id;
        localStorage.setItem('supplyChainId', userData.default_supply_chain);
        localStorage.setItem('nodeID', nodeId);
        this._dataService.userBasicDetails.set({ first_name: userData.first_name, last_name: userData.last_name, image: userData.image, policy_accepted: userData.policy_accepted })
        this._dataService.supplyChainIdSignal.set(userData.default_supply_chain)
        this._dataService.nodeId.set(nodeId)
      }
    });
  }
}



