import { canMatchLogin } from './services/guard/canMatchLogin';
export const authenticationRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then((m) => m.LoginComponent),
    data: { showComponents: false },
    // canLoad: [IsLoggedIn],
    canMatch: [canMatchLogin],
  },
  {
    path: 'welcome',
    loadComponent: () =>
      import('./invite-connection/invite-connection.component').then(
        (m) => m.InviteConnectionComponent
      ),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent
      ),
  },
  {
    path: 'reset',
    loadComponent: () =>
      import('./reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent
      ),
  },
  {
    path: 'set-password',
    loadComponent: () =>
      import('./set-password/set-password.component').then(
        (m) => m.SetPasswordComponent
      ),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./privacy-policy/privacy-policy.component').then(
        (m) => m.PrivacyPolicyComponent
      ),
  },
];
