import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/shared/services/data/data.service';
import { AuthData, Response } from 'src/app/shared/shared-components/models/common-models';
import { environment } from 'src/environments/environment';
import {
  DashboardTheme,
  PrivacyPolicy,
  loginForm,
} from '../../login/models';
import {
  TokenVerification,
  ValidateTenantDomain,
} from '../../models/auth-models';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public projectName = environment.projectName;
  $baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private fb: FormBuilder,
    private _dataService: DataService
  ) { }

  get isVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('adminData'));
    // console.log(user)
    if (user && user?.email_verified) {
      if (user.status > 1) {
        this.router.createUrlTree(['/']);
        return false;
      } else {
        return true;
      }
    } else {
      this.router.createUrlTree(['/']);
      return false;
    }
  }

  getLoginForm(): FormGroup<loginForm> {
    return this.fb.group<loginForm>({
      username: this.fb.control('', [
        Validators.required,
        RxwebValidators.email(),
      ]),
      password: this.fb.control('', Validators.required),
      // loggedIn: [],
    });
  }

  // USER

  getEmailAvailability(email) {
    const url = '/users/email/availability/?email=' + email;
    return this.getData(url);
  }

  getUnameAvailability(uname) {
    const url = '/users/username/availability/?username=' + uname;
    return this.getData(url);
  }

  getPswdAvailability(pswd) {
    const url = '/users/password/validity/?password=' + pswd;
    return this.getData(url);
  }

  memberSignup(data) {
    const url = '/users/member/signup/';
    return this.postData(url, data);
  }

  validateEmail(data) {
    const url = '/users/email/verify/';
    return this.postData(url, data);
  }

  validatePassword(data) {
    const url = '/auth/password/check/';
    return this.postData(url, data);
  }

  forgotPassword(email) {
    const data = {
      email,
      tenant: localStorage.getItem('tenantId'),
    };
    const url = '/auth/password/reset/';
    return this.postData(url, data);
  }

  login(data) {
    const url = '/auth/login/';
    return this.postData<AuthData>(url, data);
  }

  validateDomain(data) {
    const url = '/tenants/domain/validate/';
    return this.postData<ValidateTenantDomain>(url, data);
  }

  getDashboardTheme(id) {
    const url = `/dashboard/tenant/${id}/theme/`;
    return this.getData<DashboardTheme>(url);
  }

  verifyResetToken(data) {
    const url = '/auth/validate/';
    return this.postData<TokenVerification>(url, data);
  }

  resetUserPassword(data) {
    const url = '/auth/password/reset/confirm/';
    return this.postData(url, data);
  }

  changePassword(data) {
    const url = '/auth/password/change/';
    return this.postData(url, data);
  }

  getPrivacyPolicy() {
    const url = `/accounts/privacy-policy/`;
    return this.getData<PrivacyPolicy>(url);
  }

  getPublicQuestionnaireDetails(id, data) {
    const url = `/questionnaire/${id}/public/`;
    return this.getDataByParams<any>(url, data);
  }

  getPublicQuestionnaireQuestions(id, data) {
    const url = `/questionnaire/${id}/questions/public/`;
    return this.getDataByParams<any>(url, data);
  }

  getPublicQuestionnaireLink(id) {
    const url = `/questionnaire/${id}/link/`;
    return this.getData(url);
  }

  logout() {
    localStorage.removeItem('ifUserLoggedin');
    localStorage.removeItem('UserAuthData');
    localStorage.removeItem('nodeID');
    localStorage.removeItem('tenantId');
    localStorage.removeItem('tenantConfigurations');
    localStorage.removeItem('supplyChainId');
    localStorage.removeItem('supplyChainName');
    localStorage.removeItem('theme');
    // console.log('User Logged Out!');
    this.router.navigateByUrl('login');
  }

  // primary methods
  getData<T>(url: string): Observable<Response<T>> {
    const baseUrl = this.$baseUrl + url;
    return this.http.get<Response<T>>(baseUrl, this.Options());
  }

  getDataByParams<T>(url: string, params): Observable<Response<T>> {
    const baseUrl = this.$baseUrl + url;
    return this.http.get<Response<T>>(baseUrl, this.Options3(params));
  }

  postData<T>(url: string, data: any): Observable<Response<T>> {
    const baseUrl = this.$baseUrl + url;
    return this.http.post<Response<T>>(baseUrl, data, this.Options());
  }

  patchData<T>(url: string, data: any): Observable<Response<T>> {
    const baseUrl = this.$baseUrl + url;
    return this.http.patch<Response<T>>(baseUrl, data, this.Options());
  }

  // httpOptions
  Options() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Accept-Language': localStorage.getItem('lang') || 'en',
      }),
    };
    return httpOptions;
  }

  Options3(params) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Accept-Language': localStorage.getItem('lang') || 'en',
      }),
      params: new HttpParams({ fromObject: params }),
    };
    return httpOptions;
  }

  getIPAddress() {
    return this.http.get<any>('https://geolocation-db.com/json/');
    // .pipe( catchError(err => {
    //     return throwError(err);
    //   }),
    //   tap(response => {
    //     console.log(response.IPv4);
    //   }) )
  }
}
