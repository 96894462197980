import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver-es';
import { BehaviorSubject, Observable, Subject, combineLatest, map } from 'rxjs';
import { TenantConstantData } from 'src/app/core/component/header/components/models/header-models';
import { ManagingNode, UserDetails } from 'src/app/core/component/side-bar/models';
import { entityData } from 'src/app/features/company-profile/models';
import { TeamMemberData } from 'src/app/features/company-profile/models/company-profile-model';
import { productData } from 'src/app/features/products/models/model';
import {
  ClaimData,
  NodeDetails,
  SupplyChain,
  stockDetails,
} from 'src/app/features/stocks/stock-model';
import { ITransaction } from 'src/app/features/transactions/model/models';
import { environment } from 'src/environments/environment';
import {
  AuthData,
  CountryData,
  DynamicFormData,
  IFormFieldValue,
  IRefreshToken,
  Response,
  TableData,
  TenantConfiguration,
  TenantConstantBasicData,
} from '../../shared-components/models/common-models';
import { toObservable } from '@angular/core/rxjs-interop';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  public readonly supplyChainData$ = signal<string>('');
  $baseUrl = environment.baseUrl;
  selectedCompany = signal('')
  public sideBarWalkThroughComplete = signal(false)
  public sideBarWalkThroughComplete$ = toObservable(this.sideBarWalkThroughComplete)
  public companyData = new BehaviorSubject<Partial<ManagingNode>>(null);
  // public entityDetails = new BehaviorSubject(null);
  // entityNotifications = [];

  countryData: CountryData[];
  // public verificationsCount = new BehaviorSubject(null);
  readonly nodeId = signal(null)
  readonly supplyChainIdSignal = signal<string>(null)
  readonly nodeId$ = toObservable(this.nodeId)
  readonly supplyChainId$ = toObservable(this.supplyChainIdSignal)

  readonly isUserDataUpdated$ = combineLatest([this.supplyChainId$, this.nodeId$]).pipe(map(res => res.every(item => item)))

  userDetails: UserDetails = null
  userProfileUpdated = new Subject<any>();
  updateSupplyChainDropdown = new Subject<any>();
  claimCreated = new Subject<any>();
  bulkUploadExcelValidated = new Subject<any>();
  bulkUploadTypeChanged = new Subject<any>();

  readonly userBasicDetails = signal({ first_name: '', last_name: '', image: '', policy_accepted: false })
  public dynamicFormSubmitted = new Subject<any>();
  public newPolicyUpdateReceived = new Subject<any>();

  isRefreshing = false;



  public router = inject(Router);
  public http = inject(HttpClient)
  get isVerified(): boolean {
    const user = this.getUserAuthData()
    if (user) {
      return true;
    } else {
      this.router.createUrlTree(['/login']);
      return false;
    }
  }

  changeRefreshKey(value: boolean) {
    this.isRefreshing = value;
  }

  getUserData(): UserDetails {
    const data = localStorage.getItem('UserData')
    return data ? JSON.parse(data) : null
  }

  setUserData(data: Partial<UserDetails>) {
    this.userDetails = { ...this.userDetails, ...data }
  }


  getUserAuthData(): AuthData {
    const data = localStorage.getItem('UserAuthData')
    return data ? JSON.parse(data) : null
  }

  setUserAuthData(data: AuthData) {
    localStorage.setItem('UserAuthData', JSON.stringify(data));
  }

  get refreshKey(): boolean {
    return this.isRefreshing;
  }

  // ----- USER -----
  editMember(id, entity) {
    const url = '/nodes/members/' + id + '/';
    return this.patchData(url, entity);
  }

  updateUserConfigurations(userConfig) {
    const config = JSON.parse(localStorage.getItem('tenant_configurations'));
    const updatedConfig = {
      ...config,
      ...userConfig,
    };
    localStorage.setItem(
      'tenant_configurations',
      JSON.stringify(updatedConfig)
    );
  }

  getTenantConfig(): TenantConfiguration {
    return JSON.parse(localStorage.getItem('tenant_configurations')) || null;
  }

  resendInvite(id) {
    const url = '/nodes/members:resend-invite';
    const baseUrl = this.$baseUrl + url;
    return this.http.post(baseUrl, { id });
  }

  getMemberDetails() {
    const id = this.getUserAuthData()?.user_id;
    const url = `/accounts/users/${id}/`;
    return this.getData<UserDetails>(url);
  }

  editUserDetails(data) {
    const id = this.getUserAuthData()?.user_id;
    const url = `/accounts/users/${id}/`;
    return this.patchData<UserDetails>(url, data);
  }

  // TENANT

  getTenantConfigurations() {
    const url = `/tenants/`;
    return this.getData<TenantConfiguration>(url);
  }

  getCountryData() {
    const params = { limit: 1000, offset: 0 };
    const url = '/tenants/countries/';
    return this.getDataByParams<TableData<CountryData>>(url, params);
  }

  refreshToken(data) {
    const url = '/auth/token/refresh/';
    return this.postData<IRefreshToken>(url, data);
  }

  verifyToken(data) {
    const url = '/token/verify/';
    return this.postData(url, data);
  }

  // DYNAMIC FORMS

  getDynamicFormKeys(id) {
    const url = `/dynamic-form/${id}/`;
    return this.getData<DynamicFormData>(url);
  }

  updateDynamicFormField(id, data) {
    const url = `/dynamic-form/field/${id}/`;
    return this.patchData(url, data);
  }

  getSubmissionId(data) {
    const url = `/dynamic-form/submission/`;
    return this.postData<{ id: string; mongo_submission_id: string }>(
      url,
      data
    );
  }

  submitFormFieldValues(id, data) {
    const url = `/dynamic-form/submission/${id}/`;
    return this.postData<string>(url, data);
  }

  getFormFieldValues(id) {
    const url = `/dynamic-form/submission/${id}/`;
    return this.getData<IFormFieldValue[]>(url);
  }

  // ----- NODE -----

  getTenantConstants() {
    const url = '/constants/';
    return this.getData<TenantConstantData>(url);
  }

  updateNodeDetails(id, data) {
    const url = `/nodes/${id}/`;
    const param:{name:string,registration_no:string,operations:string[]}={
      name:data.name,
      registration_no:data.registration_no,
      operations:[data.operation]
    }
    return this.patchData<entityData>(url, param);
  }

  getNodeList(params) {
    const url = '/supply-chain/connection/';
    return this.getDataByParams<TableData<NodeDetails>>(url, params);
  }

  searchNodes(params) {
    const url = '/nodes/search/';
    return this.getDataByParams(url, params);
  }

  filterNodesList(params) {
    const url = '/nodes/ai/search/';
    return this.getDataByParams<TenantConstantBasicData>(url, params);
  }

  getNodeDetails(id) {
    const url = `/nodes/${id}/`;
    return this.getData<entityData>(url);
  }

  getTeamMembers(params) {
    const url = '/nodes/members/';
    return this.getDataByParams<TableData<TeamMemberData>>(url, params);
  }

  initiateBulkUpload(id, data) {
    const url = `/bulk/uploads/${id}/:initiate`;
    return this.postData(url, data);
  }

  // REPORT

  createReport(data) {
    const url = '/reports/create/';
    return this.postData(url, data);
  }

  getEntityReport(id_entity, id_member) {
    const url = '/reports/get/?user_id=' + id_member + '&node_id=' + id_entity;
    return this.getData(url);
  }

  downloadReport(data) {
    const url =
      '/reports/' +
      data.id +
      '/download/?user_id=' +
      data.id_member +
      '&node_id=' +
      data.id_entity;
    return this.getFile(url, data);
  }

  // ----- PRODUCT -----

  getProducts(params) {
    params = { ...params, supply_chain: localStorage.getItem('supplyChainId') };
    const url = '/products/';
    return this.getDataByParams<TableData<productData>>(url, params);
  }

  getSupplyChainList(data) {
    const url = '/supply-chain/';
    return this.getDataByParams<TableData<SupplyChain>>(url, data);
  }

  getBatches(params) {
    const url = '/products/batch/';
    return this.getDataByParams<TableData<stockDetails>|stockDetails[]>(url, params);
  }

  getStockDetails(id) {
    const url = '/products/batch/' + id + '/';
    return this.getData(url);
  }
  updateStockDetailsData(id, data) {
    const url = '/products/batch/' + id + '/';
    return this.patchData(url, data);
  }

  getExternalTransactionsList(params) {
    const url = '/transactions/external/';
    return this.getDataByParams(url, params);
  }

  getInternalTransactionsList(params) {
    const url = '/transactions/internal/';
    return this.getDataByParams(url, params);
  }

  getTransactionDetails(id, type) {
    let url;
    if (type == 1) {
      url = '/transactions/external/' + id + '/';
    } else {
      url = '/transactions/internal/' + id + '/';
    }
    return this.getData<ITransaction>(url);
  }

  getClaims(data) {
    const url = '/claims/';
    return this.getDataByParams<TableData<ClaimData>>(url, data);
  }

  attachClaim(data) {
    const url = '/claims/attach/';
    return this.postData(url, data);
  }

  attachClaimFiles(params, data) {
    const url = '/claims/file/create/';
    return this.postFileWithParams(url, data, params);
  }

  deleteClaimFile(id) {
    const url = '/claims/file/' + id + '/delete/';
    return this.postData(url, '');
  }

  getBulkUploadTemplate(type) {
    const data = {
      type: type,
      supply_chain: localStorage.getItem('supplyChainId'),
    };
    const url = '/bulk/template/';
    return this.getFile(url, data);
  }

  downloadFile(file, name) {
    fetch(file, { method: 'GET' })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, name);
      })
      .catch((err) => {
        console.error('err: ', err);
      });
  }

  verifyBulkUploadTemplate(file) {
    const url = '/bulk/uploads/';
    return this.postFile(url, file);
  }

  reVerifyBulkUploadTemplate(id, data) {
    const url = `/bulk/uploads/${id}/`;
    return this.patchData(url, data);
  }

  setTableFilters(type, data) {
    if (type == 201) {
      if (data && (data.tableDataType == 1 || data.tableDataType == 2)) {
        localStorage.setItem('supplierConnectionFilters', null);
        localStorage.setItem('buyerConnectionFilters', null);
        localStorage.setItem('transactionFilters', null);
        localStorage.setItem('stockFilters', null);
        localStorage.setItem('stockHistoryFilters', null);
        localStorage.setItem('ordersFilters', null);
        localStorage.setItem('verificationFilters', null);
        localStorage.setItem('bulkUploadFilters', null);
        localStorage.setItem('connectionFilters',null)
      }
    } else {
      localStorage.setItem('supplierConnectionFilters', null);
      localStorage.setItem('buyerConnectionFilters', null);
      localStorage.setItem('transactionFilters', null);
      localStorage.setItem('stockFilters', null);
      localStorage.setItem('stockHistoryFilters', null);
      localStorage.setItem('ordersFilters', null);
      localStorage.setItem('verificationFilters', null);
      localStorage.setItem('bulkUploadFilters', null);
      localStorage.setItem('connectionFilters', null)
    }
    if (type == 101) {
      localStorage.setItem('supplierConnectionFilters', JSON.stringify(data));
    } else if (type == 102) {
      localStorage.setItem('buyerConnectionFilters', JSON.stringify(data));
    } else if (type == 201) {
      localStorage.setItem('transactionFilters', JSON.stringify(data));
    } else if (type == 301) {
      localStorage.setItem('stockFilters', JSON.stringify(data));
    } else if (type == 302) {
      localStorage.setItem('stockHistoryFilters', JSON.stringify(data));
    } else if (type == 401) {
      localStorage.setItem('ordersFilters', JSON.stringify(data));
    } else if (type == 501) {
      localStorage.setItem('verificationFilters', JSON.stringify(data));
    } else if (type == 601) {
      localStorage.setItem('bulkUploadFilters', JSON.stringify(data));
    }
    else if (type === 701) {
      localStorage.setItem('connectionFilters', JSON.stringify(data));
    }
  }

  getTableFilters(type) {
    if (type == 101) {
      return JSON.parse(localStorage.getItem('supplierConnectionFilters'));
    } else if (type == 102) {
      return JSON.parse(localStorage.getItem('buyerConnectionFilters'));
    } else if (type == 201) {
      return JSON.parse(localStorage.getItem('transactionFilters'));
    } else if (type == 301) {
      return JSON.parse(localStorage.getItem('stockFilters'));
    } else if (type == 302) {
      return JSON.parse(localStorage.getItem('stockHistoryFilters'));
    } else if (type == 401) {
      return JSON.parse(localStorage.getItem('ordersFilters'));
    } else if (type == 501) {
      return JSON.parse(localStorage.getItem('verificationFilters'));
    } else if (type == 601) {
      return JSON.parse(localStorage.getItem('bulkUploadFilters'));
    }
    else if (type === 701) {
      return JSON.parse(localStorage.getItem('connectionFilters'));
    }
  }

  clearAllFilters() {
    localStorage.removeItem('stockFilters');
    localStorage.removeItem('transactionFilters');
    localStorage.removeItem('supplierConnectionFilters');
    localStorage.removeItem('stockHistoryFilters');
    localStorage.removeItem('ordersFilters');
    localStorage.removeItem('verificationFilters');
    localStorage.removeItem('bulkUploadFilters');
    localStorage.removeItem('buyerConnectionFilters');
    localStorage.removeItem('buyerConnectionFilters');
    localStorage.removeItem('connectionFilters')
  }

  noWhitespaceValidator(control: FormControl): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }

  preFillSelectedValues(selectedValue, filteredList = []) {
    if (selectedValue && filteredList.length > 0) {
      const index = filteredList.findIndex(
        (option) => option.id == selectedValue.id
      );
      if (index == -1) {
        selectedValue.hidden = true;
        filteredList.unshift(selectedValue);
      }
    }
  }

  updateFormAndSubmit(
    formIds: any[],
    index: number,
    { isValidKey = 'is_valid', submissionIdKey = 'submission_id', event },
    submitCallback
  ) {
    formIds[index][isValidKey] = event.isValid;
    formIds[index][submissionIdKey] = event.submissionId;

    const isFormValid = formIds.every((form) => form[isValidKey]);

    if (isFormValid) {
      submitCallback();
      return true;
    } else {
      return false;
    }
  }

  // primary methods
  getData<T>(url: string): Observable<Response<T>> {
    const baseUrl = this.$baseUrl + url;
    return this.http.get<Response<T>>(baseUrl);
  }

  getDataByParams<T>(url: string, params): Observable<Response<T>> {
    const baseUrl = this.$baseUrl + url;
    return this.http.get<Response<T>>(baseUrl, this.Options2(params));
  }

  getFile(url: string, params) {
    const baseUrl = this.$baseUrl + url;
    return this.http.get(baseUrl, this.Options3(params));
  }

  postData<T>(url: string, data: any): Observable<Response<T>> {
    const baseUrl = this.$baseUrl + url;
    return this.http.post<Response<T>>(baseUrl, data);
  }

  postFormData(url: string, data: any) {
    const baseUrl = this.$baseUrl + url;
    return this.http.post(baseUrl, data);
  }

  patchData<T>(url: string, data: any): Observable<Response<T>> {
    const baseUrl = this.$baseUrl + url;
    return this.http.patch<Response<T>>(baseUrl, data);
  }

  postFile<T>(url: string, data: any): Observable<Response<T>> {
    const baseUrl = this.$baseUrl + url;
    return this.http.post<Response<T>>(baseUrl, data);
  }

  postFileWithParams(url: string, data: any, params: any) {
    const baseUrl = this.$baseUrl + url;
    return this.http.post(baseUrl, data, this.Options2(params));
  }

  deleteData<T>(url: string): Observable<Response<T>> {
    const baseUrl = this.$baseUrl + url;
    return this.http.delete<Response<T>>(baseUrl);
  }

  // httpOptions
  Options2(param) {
    const httpOptions = {
      params: new HttpParams({ fromObject: param }),
    };
    return httpOptions;
  }

  Options3(param) {
    const httpOptions = {
      params: new HttpParams({ fromObject: param }),
      responseType: 'arraybuffer' as 'json',
    };
    return httpOptions;
  }

  updateSupplyChainChangeStatus(value: string) {
    this.supplyChainData$.set(value);
  }

}
