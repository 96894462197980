<div id="view-files" class="row mx-0">
  <div class="col-12 px-0">
    <div class="row dialog-header">
      <div class="col-10">
        <p>{{ "Privacy policy update" | translate }}</p>
      </div>
    </div>
  </div>
  <div class="col-12 px-0">
    <p class="body-text mb-0">
      {{
        "We have updated our privacy policy. By continuing to use our services, you acknowledge these updates. Please review our updated privacy policy below for more details."
          | translate
      }}
    </p>
    <div class="mt-4">
      <mat-checkbox [(ngModel)]="termsAccepted">
        <span class="checkbox-label">
          {{ "I have read and agree with the updated " | translate }}
          <a target="_blank" [routerLink]="['/privacy-policy']">
            <u>{{ "privacy policy agreement" | translate }}</u>
          </a>
        </span>
      </mat-checkbox>
    </div>
  </div>
  <div class="col-12 pt-5 d-flex justify-content-center">
    <button
      [disabled]="!termsAccepted || submitted"
      class="btn btn-custom-1 btn-custom-md"
      (click)="acceptTerms()"
    >
      {{ "Continue" | translate }}
    </button>
  </div>
</div>
