import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { AuthService } from '../auth/auth.service';

export const canMatchLogin: CanMatchFn = (

) => {
  if (localStorage.getItem('ifUserLoggedin')) {
    const params = [];
    // console.log('Query param ', next.queryParams);
    // check if it's a magic link
    // .token && params.salt && params.user
    // console.log(params)
    if (params && params.length > 0) {
      return true;
    } else {
      // inject(Router).navigate(['/']);
      // return false;
      return inject(AuthService).isVerified;
    }
  } else {
    return true;
  }
};
