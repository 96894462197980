import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';

export const canMatchFeature: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
) => {
  if (localStorage.getItem('ifUserLoggedin')) {
    return true;
  } else {
    const router = inject(Router);
    if (!router.url.includes('login')) {
      if (segments.length == 0) {
        segments.push(new UrlSegment('login', {}));
      }
    }
    return false;
  }
};
